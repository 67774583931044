import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import PageLayout from '../components/PageLayout';

interface Props {
  pageContext: {
    tag: string;
  };
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: [
        {
          node: {
            excerpt: string;
            timeToRead: number;
            frontmatter: {
              date: string;
              title: string;
              description: string;
            };
            fields: {
              slug: string;
            };
          };
        }
      ];
    };
  };
}

const PostsByCategoryTemplate = ({ pageContext, data }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  const { tag } = pageContext;
  const { edges: posts, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } with category ${tag}`;
  const title = `Category: ${tag} | ${siteTitle}`;

  return (
    <PageLayout>
      <SEO title={title} />
      <header>
        <h1>{tagHeader}</h1>
      </header>

      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <article key={node.fields.slug} style={{ paddingBottom: '0.1rem' }}>
            <header>
              <h3
                style={{
                  marginBottom: '0.4rem',
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{`${node.frontmatter.date} • ${node.timeToRead} min read`}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        );
      })}
    </PageLayout>
  );
};

export default PostsByCategoryTemplate;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
